import React, { Component } from "react";
import PropTypes from "prop-types";
import Text from "@/bit/components/components.text";
import Glyph from "@/bit/components/components.icon-glyph";
// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle

export const POSITION = {
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",
};

export const LAYOUT = {
  COMPACT: "compact",
  BAND: "band",
};

export const THEME = {
  WHITE: "white",
  BLUE: "blue_a",
  BLUE_DARK: "dark_blue",
};

export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
};

// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
export const mapNumber = (number, inmin, inmax, outmin, outmax) =>
  ((number - inmin) * (outmax - outmin)) / (inmax - inmin) + outmin;

export const isValidDate = (dateObj) => {
  if (Object.prototype.toString.call(dateObj) === "[object Date]") {
    // it is a date
    if (isNaN(dateObj.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    }
    // date is valid
    return true;
  }
  // not a date
  return false;
};

export const setUpDate = (startDate, endDate, visibleItems) => {
  let strDateTimeIniNormalized = startDate;
  let strDateTimeFinNormalized = endDate;

  let dateIni = new Date(strDateTimeIniNormalized);
  const dateFin = new Date(strDateTimeFinNormalized);
  if (strDateTimeIniNormalized == "") {
    dateIni = new Date();
  }
  if (!isValidDate(dateIni)) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  } else {
    if (dateIni <= new Date()) {
      dateIni = new Date();
    } else {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
  }
  if (!isValidDate(dateFin)) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  const firstDiff = dateFin.getTime() - dateIni.getTime();
  if (firstDiff < 0) {
    // warn
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  const diff = new Date(firstDiff);

  // const years = diff.getUTCFullYear() - 1970; // Gives difference as year
  // let months = diff.getUTCMonth(); // Gives month count of difference

  const visibleDays = visibleItems?.visibleDays;

  const days = diff.getUTCDate() - 1; // Gives day count of difference
  const hours = visibleDays
    ? diff.getUTCHours()
    : days * 24 + diff.getUTCHours();
  const minutes = diff.getUTCMinutes();
  const seconds = diff.getUTCSeconds();

  // const totalSeconds = diff.getTime() / 1000;
  /* Tanslate to seconds */
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

/**
 * Stateless component to draw a clCountDownTimeraim
 * @param {string} finishTime set when the counter will stop
 * @param {string} colorTheme appearances one of: THEME
 * @returns {JSX} Component JSX.
 */
class CountDownDateTimer extends Component {
  constructor(props) {
    super(props);
    const { startDate, endDate, visibleItems } = this.props;
    const duration = setUpDate(startDate, endDate, visibleItems);
    const { days, hours, minutes, seconds } = duration;
    let _render = false;
    if (
      duration &&
      (duration.days > 0 ||
        duration.hours > 0 ||
        duration.minutes > 0 ||
        duration.seconds > 0)
    ) {
      _render = true;
    }
    this.state = {
      render: _render,
      days: days || 0,
      hours: hours || 0,
      minutes: minutes || 0,
      seconds: seconds || 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { startDate, endDate } = this.props;
      const duration = setUpDate(startDate, endDate);
      if (
        duration &&
        (duration.days > 0 ||
          duration.hours > 0 ||
          duration.minutes > 0 ||
          duration.seconds > 0)
      ) {
        const { days, hours, minutes, seconds } = duration;
        this.setState({
          render: true,
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        this.setState({
          render: false,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  decideCircleColor = (colorTheme) => {
    switch (colorTheme) {
      case THEME.BLUE:
        return {
          counter: "#019df4",
          bg: "rgba(1, 157, 244, 0.5)",
        };
      case THEME.BLUE_DARK:
        return {
          counter: "#0b2739",
          bg: "rgba(11, 39, 57, 0.5)",
        };
      default:
        return {
          counter: "white",
          bg: "rgba(255,255,255, 0.5)",
        };
    }
  };

  render() {
    const { color, position, visibleItems, layout } = this.props;

    const { visibleDays, visibleHours, visibleMinutes, visibleSeconds } =
      visibleItems;

    const textInfo = this.props["Text-1"];
    const iconLoader = this.props["Glyph-1"];
    const circleColor = this.decideCircleColor(color);
    const { days, hours, minutes, render } = this.state;
    let { seconds } = this.state;
    const daysRadius = mapNumber(days, 30, 0, 360, 0);
    const hoursRadius = mapNumber(hours, 24, 0, 360, 0);
    const minutesRadius = mapNumber(minutes, 60, 0, 360, 0);
    const secondsRadius = mapNumber(seconds, 60, 0, 360, 0);

    if (seconds < 0) {
      seconds = 0;
    }
    let fadeOutCss = "";
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      fadeOutCss = " countdown-wrapper--fadeout";
    }

    if (!render) {
      return null;
    }

    return (
      <>
        <div
          className={`countdown-wrapper-${layout}${fadeOutCss} theme-${color}`}
        >
          <div className="countdown_container_main">
            {(textInfo || iconLoader) && (
              <div className="countdown_text">
                {iconLoader && <Glyph {...iconLoader} />}
                {textInfo && <Text {...textInfo} />}
              </div>
            )}
            <div
              className={`countdown_container countdown_container--${position}`}
            >
              {visibleDays && days >= 0 && (
                <div className="countdown-time-item days">
                  <div className="countdown-item">
                    <SVGCircle color={circleColor.bg} radius={359.999} />
                    <SVGCircle
                      color={circleColor.counter}
                      radius={daysRadius}
                    />
                    <Text text={days} color={color} />
                  </div>
                  <span className="label-pc">días</span>
                  <span className="label-mobile">d</span>
                  {layout && layout == "compact" && (
                    <Text text=":" color={color} />
                  )}
                </div>
              )}
              {visibleHours && hours >= 0 && (
                <div className="countdown-time-item hours">
                  <div className="countdown-item">
                    <SVGCircle color={circleColor.bg} radius={359.999} />
                    <SVGCircle
                      color={circleColor.counter}
                      radius={hoursRadius}
                    />
                    <Text text={hours.toString().padStart(2, '0')} color={color} />
                  </div>
                  <span className="label-pc">horas</span>
                  <span className="label-mobile">h</span>
                  {layout && layout == "compact" && (
                    <Text text=":" color={color} />
                  )}
                </div>
              )}
              {visibleMinutes && minutes >= 0 && (
                <div className="countdown-time-item minutes">
                  <div className="countdown-item">
                    <SVGCircle color={circleColor.bg} radius={359.999} />
                    <SVGCircle
                      color={circleColor.counter}
                      radius={minutesRadius}
                    />
                    <Text text={minutes.toString().padStart(2, '0')} color={color} />
                  </div>
                  <span className="label-pc">minutos</span>
                  <span className="label-mobile">m</span>
                  {layout && layout == "compact" && (
                    <Text text=":" color={color} />
                  )}
                </div>
              )}
              {visibleSeconds && seconds >= 0 && (
                <div className="countdown-time-item seconds">
                  <div className="countdown-item">
                    <SVGCircle color={circleColor.bg} radius={359.999} />
                    <SVGCircle
                      color={circleColor.counter}
                      radius={secondsRadius}
                    />
                    <Text text={seconds.toString().padStart(2, '0')} color={color} />
                  </div>
                  <span className="label-pc">segundos</span>
                  <span className="label-mobile">s</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <style jsx>{`
.countdown-wrapper-band {
  font-family: "Light";
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  padding: 0px 0px;
  &.theme-blue_a {
    background-color: transparent;
    :global(span) {
      color: #019df4;
    }
  }
  &.theme-dark_blue {
    background-color: transparent;
    :global(span) {
      color: #0b2739;
    }
  }
  &.theme-white {
    background-color: transparent;
    :global(span) {
      color: white;
    }
  }
  .countdown_text {
    display: flex;
    gap: 0.25rem;
    :global(.text) {
      font-size: 14px;
    }
  }
  .countdown_container_main {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    .countdown_container {
      display: flex;
      flex-wrap: nowrap;
      .countdown-time-item {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        :global(span) {
          font-size: 14px;
        }
        .countdown-item {
          position: relative;
          color: #111;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.25rem;
          width: 40px;
          :global(.countdown-svg) {
            position: absolute;
            --top: 0;
            --left: 0;
            width: 100px;
            height: 100px;
            transform: scale(0.28, 0.28);
          }
          :global(span) {
            font-size: 14px;
          }
        }
        :global(.label-pc),
        :global(.label-mobile) {
          font-size: 1rem;
          display: none;
        }
      }
    }
  }
  &--fadeout {
    animation: countDownFadeOut 0.3s linear forwards;
  }
}
.countdown-wrapper-compact {
  font-family: "Light";
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: auto;
  padding: 0px 0px;
  .countdown_text {
    display: flex;
    gap: 0.25rem;
    :global(.text) {
      font-size: 14px;
    }
  }
  .countdown_container_main {
    display: flex;
    gap: 0.25rem;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: flex-end;
    .countdown_container {
      display: flex;
      .countdown-time-item {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        :global(span) {
          font-size: 14px;
        }
        .countdown-item {
          position: relative;
          color: #111;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.25rem;
          :global(.countdown-svg) {
            display: none;
            position: absolute;
            --top: 0;
            --left: 0;
            width: 100px;
            height: 100px;
            transform: scale(0.28, 0.28);
          }
        }
        :global(.label-pc),
        :global(.label-mobile) {
          font-size: 14px;
          display: none;
        }
      }
    }
  }
  &--fadeout {
    animation: countDownFadeOut 0.3s linear forwards;
  }
}
@media screen and (min-width: 768px) {
  .countdown-wrapper-band {
    .countdown_text {
      gap: 0.5rem;
      align-items: center;
      :global(.text) {
        font-size: 1rem;
      }
    }
    .countdown_container_main {
      justify-content: left;
      align-items: flex-end;
      .countdown_container {
        .countdown-time-item {
          .countdown-item {
            :global(span) {
              font-size: 1rem;
            }
          }
          :global(span) {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .countdown-wrapper-compact {
    .countdown_text {
      gap: 0.5rem;
      align-items: center;
      :global(.text) {
        font-size: 1rem;
      }
    }
    .countdown_container_main {
      justify-content: left;
      align-items: center;
      .countdown_container {
        .countdown-time-item {
          :global(span) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .countdown-wrapper-band {
    .countdown_container_main {
      .countdown_container {
        display: inline-flex;
        width: initial;
        max-width: none;
        .countdown-time-item {
          .countdown-item {
            font-size: 1rem;
            display: flex;
            .countdown-svg {
              position: absolute;
              width: 100px;
              height: 100px;
              transform: scale(0.28, 0.28);
            }
            :global(span) {
              font-size: 1rem;
            }
          }
          :global(.label-pc) {
            display: block;
          }
          :global(.label--mobile) {
            display: none;
          }
        }
      }
    }
  }
  .countdown-wrapper-compact {
    .countdown_text {
      gap: 0.5rem;
      align-items: center;
      :global(.text) {
        font-size: 1.25rem;
      }
    }
    .countdown_container_main {
      .countdown_container {
        display: inline-flex;
        width: initial;
        max-width: none;
        .countdown-time-item {
          .countdown-item {
            font-size: 1.25rem;
            display: flex;
            :global(.countdown-svg) {
              position: absolute;
              width: 100px;
              height: 100px;
              transform: scale(0.28, 0.28);
            }
            :global(span) {
              font-size: 1.25rem;
            }
          }
          :global(.label-pc),
          :global(.label-mobile) {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes countDownFadeOut {
  0% {
    opacity: 1;
    height: auto;
  }
  99% {
    height: auto;
  }
  100% {
    opacity: 0;
    height: 0px;
  }
}`}</style>
      </>
    );
  }
}

const SVGCircle = ({ radius, color }) => {
  return (
    <svg className="countdown-svg">
      <path
        fill="none"
        stroke={`${color}`}
        strokeWidth="6"
        d={describeArc(50, 50, 44, 0, radius)}
      />
    </svg>
  );
};

CountDownDateTimer.propTypes = {
  /**
   * {
   *  "info": "Componente TextInfo junto a sus props",
   *  "kind": "both",
   *  "beautifulName": "TextInfo-1"
   * }
   */
  components: PropTypes.object,
  /**
   * {
   *  "info": "Tiempo de duración del contador",
   *  "kind": "both",
   *  "beautifulName": "Duración"
   * }
   */
  finishTime: PropTypes.string,
  /**
   * {
   *  "info": "Tema del contador, sirve para definir el color del texto, el color de background, la alineación del texto,... ",
   *  "kind": "both",
   *  "beautifulName": "Tema"
   * }
   */
  color: PropTypes.oneOf([THEME.WHITE, THEME.BLUE, THEME.BLUE_DARK]),
  /**
   * {
   *  "info": "Posicion de los contadores",
   *  "kind": "both",
   *  "beautifulName": "position"
   * }
   */
  position: PropTypes.oneOf([POSITION.CENTER, POSITION.RIGHT, POSITION.LEFT]),
  /**
   * {
   *  "info": "flag para visibilizar los días ,horas minutos o segundos",
   *  "kind": "both",
   *  "beautifulName": "visibleSeconds"
   * }
   */
  visibleItems: PropTypes.object,
};

CountDownDateTimer.defaultProps = {
  components: {},
  startDate: "",
  endDate: "",
  color: THEME.WHITE,
  position: POSITION.LEFT,
  layout: LAYOUT.BAND,
  visibleItems: {
    visibleDays: true,
    visibleHours: true,
    visibleMinutes: true,
    visibleSeconds: true,
  },
};

export default CountDownDateTimer;
